import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLinks"] */ "/vercel/path0/components/NavLinks.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/images/perceivable-logo-dark.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/images/perceivable-logo-light.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
